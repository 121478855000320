<template>
  <!-- File Dropdown Starts-->
  <div>
    <b-dropdown
      variant="link"
      no-caret
      toggle-class="p-0 file-dropdown"
      class="toggle-dropdown mt-n25"
      right
    >
      <template #button-content>
        <feather-icon icon="MoreVerticalIcon" class="text-body" />
      </template>
      <b-dropdown-item
        v-for="(menu, index) in menus"
        @click="$emit('trigger-action', menu.action)"
        :key="index"
      >
        <feather-icon :icon="menu.icon" class="align-middle mr-50" />
        <span class="align-middle">{{ menu.name }}</span>
      </b-dropdown-item>
    </b-dropdown>
  </div>
  <!-- /File Dropdown Ends -->
</template>

<script>
import { BDropdown, BDropdownItem } from "bootstrap-vue";
import ability from "@/libs/acl/ability";

export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  props: ["belongsTo", "menusList"],
  data() {
    return {
      menus: [],
    };
  },
  watch: {
    menuList: {
      handler: "canViewMenus",
      deep: true,
      immediate: true,
    },
  },
  methods: {
    canViewMenus() {
      this.menus = this.menusList.filter((menu) => {
        if (
          (menu.action == "open" &&
            ability.cannot("read_archives", "Bibliothèque")) ||
          (menu.action == "move" &&
            ability.cannot("edit_archive", "Bibliothèque"))
        )
          return false;
        if (menu.action == "delete" && (this.belongsTo == "folder" && ability.cannot("delete_folder", "Bibliothèque")) || (this.belongsTo == "file" && ability.cannot("delete_archive", "Bibliothèque")))
          return false;
        return true;
      });
    },
  },
};
</script>
