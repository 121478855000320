<template>
  <div class="view-container">
    <h6 class="files-section-title mt-25 mb-75">Dossiers</h6>
    <div class="files-header">
      <h6 class="font-weight-bold mb-0">Nom de fichier</h6>
      <div>
        <h6 class="font-weight-bold file-last-modified d-inline-block mb-0">Modifié le</h6>
        <h6 class="font-weight-bold d-inline-block mr-1 mb-0">Actions</h6>
      </div>
    </div>
    <div class="card file-manager-item folder level-up">
      <!-- <div class="file-logo-wrapper">
        <div class="d-flex align-items-center justify-content-center w-100">
          <feather-icon icon="ArrowUpIcon" />
        </div>
      </div>
      <div class="card-body pl-2 pt-0 pb-1">
        <div class="content-wrapper">
          <p class="card-text file-name mb-0">...</p>
        </div>
      </div> -->
    </div>
    <folder-card v-for="(folder, index) in folders" :key="index" :folder="folder" class="card file-manager-item folder" />
    <div class="d-none flex-grow-1 align-items-center no-result mb-3">
      <feather-icon icon="AlertCircleIcon" class="mr-50" />
      Pas de résultats
    </div>
  </div>
</template>

<script>
import { ref, } from '@vue/composition-api'
import FolderCard from '@/components/file-manager/folders/FolderCard.vue'

export default {
  name: "Folders",
  props: {
    folders: {
      type: Array,
      default: function() {
        return []
      }
    },
  },
  components: {
    FolderCard,
  },
}
</script>
