<template>
  <div>
    <!-- <b-form-checkbox /> -->
    <div class="card-img-top file-logo-wrapper">
      <file-dropdown
        :menusList="dropdownMenu"
        belongsTo="folder"
        @trigger-action="handleAction"
        class="dropdown float-right"
      />
      <div class="d-flex align-items-center justify-content-center w-100">
        <feather-icon icon="FolderIcon" />
      </div>
    </div>
    <div class="card-body">
      <div class="content-wrapper">
        <span
          @dblclick="handleAction('open')"
          class="card-text btn-link file-name mb-0"
          >{{ folder.name }}</span
        >
        <!-- <p class="card-text file-size mb-0"></p> -->
        <!-- <p class="card-text file-size mb-0">{{ folder.size }}</p> -->
        <p class="card-text file-date text-center mr-1">{{ createdAt }}</p>
      </div>
      <small class="file-accessed text-muted"
        >Dernière modification: {{ updatedAt }}</small
      >
    </div>

    <!-- Move folder modal -->
    <b-modal
      id="move-media"
      v-model="moveMediaModal"
      scrollable
      title="Déplacer un dossier"
      cancel-title="Fermer"
      ok-title="Déplacer Dossier/Fichier"
      @ok="moveFolder"
      @hidden="resetModal"
      cancel-variant="outline-secondary"
    >
      <div>
        <i class="text-muted" v-if="isFetching"
          >Récupération de dossiers en cours...</i
        >
        <div class="my-1" v-else>
          <div
            class="d-flex flex-row justify-content-start align-items-center level-up"
          >
            <div @click="isRoot = true" class="root-dir font-weight-bold pb-1">
              <feather-icon
                class="font-weight-bold cursor-pointer"
                icon="ArrowUpIcon"
              />
            </div>
            <div
              v-if="currentFolder"
              @click="fetchFolder(currentFolder.parent_uid)"
              class="prev-dir ml-2"
            >
              <p class="font-weight-bold cursor-pointer">...</p>
            </div>
          </div>
          <b-list-group v-if="filteredFolders.length > 0">
            <b-list-group-item
              v-for="folder in filteredFolders"
              :key="folder.uid"
              class="btn-link"
              @dblclick="fetchFolder(folder.uid)"
              button
            >
              <div class="d-flex flex-row justify-content-between text-left">
                <div>
                  <feather-icon icon="FolderIcon" />
                  <span class="card-text btn-link ml-2">{{
                    folder.name.toUpperCase()
                  }}</span>
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
          <i class="text-muted" v-else>Aucun dossier disponible.</i>
          <div class="d-flex flex-column mt-2">
            <div>
              <p>Dossier de destination sélectionné :</p>
            </div>
            <div
              class="d-flex flex-row justify-content-start align-items-center"
            >
              <span
                class="d-inline-block text-truncate text-bold"
                style="max-width: 200px"
                >{{ destination }}</span
              >
              <feather-icon
                class="ml-1 text-primary text-md"
                icon="CheckIcon"
              />
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "@axios";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { computed, ref, watch } from "@vue/composition-api";
import { useRouter } from "@core/utils/utils";
import FileDropdown from "@/views/apps/file-manager/FileDropdown.vue";
import { BListGroup, BListGroupItem } from "bootstrap-vue";

import moment from "moment";
moment.locale("fr");

// set axios request headers
const requestOptions = {
  headers: {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
  },
};
export default {
  name: "FolderCard",
  props: ["folder"],
  components: {
    FileDropdown,
    BListGroup,
    BListGroupItem,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  setup(props) {
    const { route, router } = useRouter();
    const toast = useToast();
    const moveMediaModal = ref(false);
    const isFetching = ref(false);
    const currentFolder = ref(null);
    const isRoot = ref(false); // check if selected folder/button is for root folders
    const isMoving = ref(false);
    const folders = ref([]);
    const formatDate = (date) => moment(new Date(date).getTime()).fromNow();

    const dropdownMenu = [
        {
          name: 'Ouvrir',
          icon: 'EyeIcon',
          action: 'open',
        },
        {
          name: 'Déplacer',
          icon: 'CornerUpLeftIcon',
          action: 'move',
        },
        {
          name: 'Supprimer',
          icon: 'TrashIcon',
          action: 'delete'
        },
      ]

    watch(isRoot, (newVal, oldVal) => {
      if (newVal) {
        fetchFolder();
      }
    });

    const handleAction = (action) => {
      const uid = props.folder.uid;

      // open folder
      if (action === "open") {
        router.push({ name: "folder-detail", params: { folder: uid } });
      }

      // move folder from source to destination
      if (action === "move") {
        if (!(Object.keys(props.folder).length > 0)) {
          throw new Error(
            "Le dossier sélectionné est invalide ou n'a pas de dossier parent"
          );
        }
        if (props.folder.parent_id == null) isRoot.value = true; // set path as root

        fetchFolder(props.folder.parent_uid); //source (the folder that contains current folder to move)

        moveMediaModal.value = true;
      }
      
      if(action === 'delete') {
        deleteFolder()
      }

      //! handle other actions from folder card dropdown
    };

    const fetchFolder = async (uid = null) => {
      isFetching.value = true;
      let url = "/files-manager/folders";

      try {
        if (uid != null) {
          url = `${url}/${uid}`;
        }

        const { data } = await axios.get(`${url}`, requestOptions);

        if (uid != null && data.hasOwnProperty("resource")) {
          isRoot.value = false;
          folders.value = data.resource?.children; // get current folders to show
          currentFolder.value = data.resource;
          delete currentFolder.value.children;
        } else if (uid == null && data.hasOwnProperty("data")) {
          isRoot.value = true;
          folders.value = data.data;
        } else {
          folders.value = [];
        }
      } catch (error) {
        if (error.response?.data)
          toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.error,
              icon: "AlertCircleIcon",
              variant: "warning",
            },
          });
        else
          toast({
            component: ToastificationContent,
            props: {
              title: error.message,
              icon: "AlertCircleIcon",
              variant: "warning",
            },
          });
      } finally {
        isFetching.value = false;
      }
    };

    const moveFolder = () => {
      isMoving.value = true;

      const folderToMove = props.folder;
      if (folderToMove && folderToMove.uid) {
        let requestPayload = {
          name: folderToMove.name,
          parent_id: isRoot.value ? null : currentFolder.value.uid,
        };

        axios
          .put(
            `/files-manager/folders/${folderToMove.uid}`,
            requestPayload,
            requestOptions
          )
          .then(({ data }) => {
            isMoving.value = false;
            toast({
              component: ToastificationContent,
              props: {
                title: `Dossier déplacé dans ${destination.value}`,
                icon: "CopyIcon",
                variant: "success",
              },
            });
          })
          .then(() => {
            if (isRoot.value || currentFolder.value == null) {
              isRoot.value = false;
              router.push({
                name: "files-manager-home",
              });
            } else {
              router.push({
                name: "folder-detail",
                params: { folder: currentFolder.value.uid },
              });
            }
          })
          .catch((error) => {
            isMoving.value = false;
            toast({
              component: ToastificationContent,
              props: {
                title: `Une erreur est survenue lors du déplacement du dossier '${folderToMove.name}'`,
                icon: "CopyIcon",
                variant: "danger",
              },
            });
            // return error;
          });
      } else {
        toast({
          component: ToastificationContent,
          props: {
            title: "Aucun dossier à déplacer",
            icon: "CopyIcon",
            variant: "danger",
          },
        });
      }
    };

    const deleteFolder = async () => {
      try {
        const folderToDelete = props.folder
        if(folderToDelete == null || folderToDelete.uid == null) {
          throw new ('Le dossier sélectionné est invalide.')
        }
        const { data } = await axios.delete(`files-manager/folders/${folderToDelete.uid}`)

        toast({
          component: ToastificationContent,
          props: {
            title: `Le dossier ${folderToDelete.name} a été supprimé avec succès.`,
            icon: "CopyIcon",
            variant: "success",
          },
        });
        
      } catch (error) {
        toast({
          component: ToastificationContent,
          props: {
            title: error.response.data.message,
            icon: "CopyIcon",
            variant: "danger",
          },
        });
      }
    }

    const resetModal = () => {
      isRoot.value = false;
      currentFolder.value = null;
    };

    const destination = computed(() => {
      if (isRoot.value) return "Acceuil";
      if (currentFolder.value != null) return currentFolder.value.name;
      return "";
    });

    const filteredFolders = computed(() => {
      return folders.value.filter((f => f.uid != props.folder.uid));
    });

    const name = computed(() => {
      let words = props.folder.name.split(" ");
      words.forEach((w, i) => {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      });
      return words.join(" ");
    });

    return {
      isFetching,
      isRoot,
      name,
      moveMediaModal,
      folders,
      dropdownMenu,
      filteredFolders,
      currentFolder,

      destination,
      fetchFolder,
      moveFolder,
      deleteFolder,
      formatDate,
      handleAction,
      resetModal,
      createdAt: computed(() => formatDate(props.folder.created_at)),
      updatedAt: computed(() => formatDate(props.folder.updated_at)),
    };
  },
};
</script>
